<template>
  <div>
    <HhDropdown ref="hhDropdown" placement="bottom">
      <template #trigger>
        <label tabindex="0" class="m-1 flex items-center p-2">
          <HhImage
            v-if="props.showFlag"
            :lazy="false"
            :src="selectedCity.attributes.countryIcon.url"
            alt="flag"
            :width="24"
            :height="24"
            class="mr-1"
            :use-mutator="true"
          >
          </HhImage>
          <div class="mr-1 truncate font-medium md:font-bold">
            {{ selectedCityName }}
          </div>
          <IconDownArrow />
        </label>
      </template>

      <template #content>
        <ul class="rounded-box bg-base-100 w-52 p-2 shadow">
          <li
            v-for="(city, idx) in cityOption"
            :key="idx"
            class="flex cursor-pointer items-center p-2 hover:bg-[#e4e4e4]"
            :class="[
              selectedCityId === city.id ? 'selected bg-[#e4e4e4]' : null,
              props.optionStyles,
            ]"
            :style="idx == 0 ? 'border-top: 1px solid #F1F1F1;' : ''"
            @click="changeCity(city.id)"
          >
            <HhImage
              v-if="props.showFlag"
              :lazy="false"
              :src="city.attributes.countryIcon.url"
              alt="flag"
              :width="24"
              :height="24"
              :use-mutator="true"
              class="mr-1"
            >
            </HhImage>
            <span class="font-medium md:font-semibold">
              {{ city.attributes.name }}
            </span>
          </li>
        </ul>
      </template>
    </HhDropdown>
  </div>
</template>
<script setup lang="ts">
import { ref } from "vue";
import { storeToRefs } from "pinia";
import HhDropdown from "../HhDropdown.vue";
import IconDownArrow from "~icons/hh-icons/icon-down-arrow";
import useCityStore from "~/stores/city";
import type { City } from "~/types/City";

const hhDropdown = ref({ onCloseDropdown: () => {} });

const props = defineProps({
  selectedStyles: {
    type: String,
    default: "",
  },
  rightLine: {
    type: Boolean,
    default: false,
  },
  paddingOption: {
    type: String,
    default: "pt-2.5",
  },
  arrowStyles: {
    type: String,
    default: "text-red-dark",
  },
  optionStyles: {
    type: String,
    default: "",
  },
  showFlag: {
    type: Boolean,
    default: false,
  },
});

const emits = defineEmits<{
  (e: "on-selected-city", data: City): void;
}>();

const cityStore = useCityStore();
const { selectedCityId, cityOption, selectedCity, selectedCityName } =
  storeToRefs(cityStore);

function changeCity(cityId: string) {
  const selectedCityOption = cityOption.value.find(
    (item) => item.id === cityId
  );
  if (selectedCityOption) {
    emits("on-selected-city", selectedCityOption);
  }

  selectedCityId.value = cityId;
  closeDropdown();
  emits("on-selected-city", selectedCity.value);
}

function closeDropdown() {
  const documentActiveElement = document.activeElement as HTMLElement | null;
  if (hhDropdown.value) {
    hhDropdown.value.onCloseDropdown();
  }
  documentActiveElement?.blur();
}
</script>
<style lang="scss" scoped>
.selected {
  cursor: pointer;
  user-select: none;
}
.custom-select .items {
  color: white;
  border-radius: 0px 0px 6px 6px;
  overflow: hidden;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 10;
}

.custom-select .items div {
  color: #3d4245;
  cursor: pointer;
  background-color: white;
  user-select: none;
  border-bottom: 1px solid #f1f1f1;
  border-left: 1px solid #f1f1f1;
  border-right: 1px solid #f1f1f1;
}
</style>
